import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ContextState from './ContextState';

import Container from './components/Container';
import RedirectToUrl from './components/RedirectToUrl';

import TermsOfUse from './pages/TermsOfUse';
import Press from './pages/Press';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PageNotFound from './pages/PageNotFound';
import CapitalHomepage from './pages/capital-homepage';
import InvestmentManagementHomepage from './pages/investment-management-homepage';
import Landing from './pages/Landing';
import Careers from './pages/careers';
import Publications from './pages/Publications';

import * as COLOURS from './constants/colours';
import './fonts/fonts.css';
import 'normalize.css';
import PredictionsDisclaimer from './pages/PredictionsDisclaimer';
import Media from './pages/Media';

const theme = {
    darkSlate: COLOURS.DARK_SLATE,
    graphite: COLOURS.GRAPHITE,
    vision: COLOURS.VISION,
    slate: COLOURS.SLATE,
    vivid: COLOURS.VIVID,
    error: COLOURS.ERROR,
    font: 'NB International',
};

const App = () => {
    const [windowWidth] = useState(window.innerWidth);

    // NOTE: this appears to reload the window every single time you resize it
    useEffect(() => {
        window.addEventListener('resize', () => {
            if (windowWidth !== window.innerWidth) {
                window.location.reload();
            }
        });

        return () => window.removeEventListener('resize', () => {});
    });

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <ContextState>
                        <Container>
                            <Switch>
                                <Route exact path="/careers">
                                    <Careers />
                                </Route>
                                <Route exact path="/press">
                                    <Press />
                                </Route>
                                <Route exact path="/privacy">
                                    <PrivacyPolicy />
                                </Route>
                                <Route exact path="/terms">
                                    <TermsOfUse />
                                </Route>
                                <Route exact path="/publications">
                                    <Publications />
                                </Route>
                                <Route exact path="/predictions-disclaimer">
                                    <PredictionsDisclaimer />
                                </Route>
                                <Route exact path="/media">
                                    <Media />
                                </Route>
                                <Route exact path="/vesparum-capital">
                                    <CapitalHomepage />
                                </Route>
                                <Route exact path="/vesparum-investment-management">
                                    <InvestmentManagementHomepage />
                                </Route>
                                <Route exact path="/">
                                    <CapitalHomepage />
                                </Route>
                                <Route path="*">
                                    <PageNotFound />
                                </Route>
                            </Switch>
                        </Container>
                    </ContextState>
                </Switch>
            </Router>
        </ThemeProvider>
    );
};

export default App;
