import React from 'react';
import styled from 'styled-components';

import Section from '../../components/Section';
import { Description, Header, SubHeader } from '../../components/StyledComponents';
import CtaButton from '../../components/CtaButton';
import SmartLink from '../../components/SmartLink';

import { DEVICES } from '../../constants/breakpoints';

const Left = styled.div`
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    width: 80%;
    z-index: 4;

    @media ${DEVICES.LAPTOP} {
        width: 55%;
    }
`;

const Right = styled.div`
    position: absolute;
    right: 0;
    top: 57%;
    transform: translateY(-50%);
    z-index: 5;

    display: none;

    @media ${DEVICES.LAPTOP} {
        display: block;
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.MOBILE_MAX} {
        font-size: 20px;
    }
`;

const StyledSubHeader = styled(SubHeader)`
    @media ${DEVICES.LAPTOP} {
        font-size: 28px;
    }

    @media ${DEVICES.MOBILE_MAX} {
        font-size: 16px;
    }
`;

const StyledDescription = styled(Description)`
    @media ${DEVICES.MOBILE_MAX} {
        font-size: 14px;
    }
`;

const GraphicContainer = styled.div`
    margin-left: 40px;
    width: 355px;
    height: 355px;
    background-color: #d9d9d9; // NOTE: not one of the theme colours
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`;

const GraphicVegaLogoContainer = styled.div`
    padding: 8px 5px 4px 5px;
    margin-bottom: 8px;

    img {
        height: 27px;
    }
`;

const GraphicLabel = styled.div`
    color: ${props => props.theme.darkSlate};
    margin-top: 10px;
`;

const GraphicIconRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
`;

const GraphicIconCell = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 130px;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
`;

const Graphic = () => (
    <GraphicContainer>
        <GraphicVegaLogoContainer>
            <img src="./images/vega.png" alt="vega logo" />
        </GraphicVegaLogoContainer>
        <GraphicLabel>Built on the latest advancements in AI.</GraphicLabel>
        <GraphicIconRow>
            <GraphicIconCell>
                <img src="./images/vega_1.png" alt="icon" />
                <GraphicLabel>Deep learning</GraphicLabel>
            </GraphicIconCell>

            <GraphicIconCell>
                <img src="./images/vega_2.png" alt="icon" />
                <GraphicLabel>Transfer learning</GraphicLabel>
            </GraphicIconCell>
        </GraphicIconRow>

        <GraphicIconRow>
            <GraphicIconCell>
                <img src="./images/vega_3.png" alt="icon" />
                <GraphicLabel>Natural language processing</GraphicLabel>
            </GraphicIconCell>

            <GraphicIconCell>
                <img src="./images/vega_4.png" alt="icon" />
                <GraphicLabel>
                    Computer
                    <br />
                    vision
                </GraphicLabel>
            </GraphicIconCell>
        </GraphicIconRow>
    </GraphicContainer>
);

const AISolutions = () => {
    // const { activeSection } = useContext(Context);
    // const inView = activeSection.name === 'ai-solutions';

    return (
        <Section invert minHeightWithLimit name="ai-solutions" displayName="AI Solutions">
            <Left>
                <StyledHeader invert underline>
                    AI solutions
                </StyledHeader>

                <StyledSubHeader invert>
                    We are a strategic AI partner – we design, build and deliver bespoke AI solutions.
                </StyledSubHeader>

                <StyledDescription colour="graphite">
                    We combine world class strategy consulting and AI engineering with our proprietary AI engine, VEGA,
                    to deliver AI solutions for our partners that drive cost-out, increase employee efficiency, increase
                    customer satisfaction and/or reduce risks.
                </StyledDescription>

                <StyledDescription colour="graphite">
                    It is this combination of talent and technology which we use to help our partners create sustainable
                    competitive advantage – and when paired with our capital solutions, can create transformative value.
                </StyledDescription>

                <CtaButton color="vision" hash="contact" is>
                    Contact us
                </CtaButton>
            </Left>

            <Right>
                <Graphic />
            </Right>
        </Section>
    );
};

export default AISolutions;
