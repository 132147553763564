import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { Context } from '../../ContextState';
import Section from '../../components/Section';
import CtaButton from '../../components/CtaButton';
import SmartLink from '../../components/SmartLink';
import {
    Description,
    Header,
    SubHeader,
    SectionContent,
    SectionContentWrapper,
    SectionIntro,
} from '../../components/StyledComponents';

import { DEVICES } from '../../constants/breakpoints';
import { VISION } from '../../constants/colours';

const StatementWrapper = styled.div`
    display: flex;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        /* Animate elements when section scrolls into view */
        ${props =>
            props.isActive &&
            css`
            opacity: 1;
            transform: translateY(0);
            transition: transform .6s ease-out, opacity .2s ease-out;

            ${props =>
                props.order === 1 &&
                css`
                    transition-delay: 0.4s;
                `}

            ${props =>
                props.order === 2 &&
                css`
                    transition-delay: 0.6s;
                `}

            ${props =>
                props.order === 3 &&
                css`
                    transition-delay: 0.8s;
                `}
        `}
    }
`;

const StatementCopy = styled.div`
    flex: 2;
    margin-left: 5%;

    @media ${DEVICES.LAPTOP} {
        margin-left: 10%;
    }
`;

const StyledHeader = styled(Header)`
    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
            `}
    }

    @media ${DEVICES.MOBILE_MAX} {
        margin-top: 10px;
        font-size: 20px;
    }

    @media ${DEVICES.TABLET_MAX} {
        margin-top: 20px;
    }
`;

const StyledSubHeader = styled(SubHeader)`
    @media ${DEVICES.LAPTOP} {
        font-size: 28px;
    }

    @media ${DEVICES.MOBILE_MAX} {
        font-size: 15px;
    }
`;

const StyledDescription = styled(Description)`
    @media ${DEVICES.MOBILE_MAX} {
        font-size: 14px;
    }
`;

const CtaButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media ${DEVICES.TABLET} {
        opacity: 0;
        transform: translateY(20vh);
        transition: transform 0.6s ease-out, opacity 0.2s ease-out;

        ${props =>
            props.isActive &&
            css`
                opacity: 1;
                transform: translateY(0);
                transition: transform 0.6s ease-out 0.4s, opacity 0.2s ease-out 0.4s;
            `}
    }
`;

const Highlighted = styled.span`
    background-color: ${VISION};
`;

const TickContainer = styled.div`
    position: absolute;
    bottom: -30px;

    display: none;

    @media ${DEVICES.TABLET} {
        display: block;
        right: -100px;
        bottom: -30px;
    }

    @media ${DEVICES.LAPTOP} {
        display: block;
        right: -300px;
        bottom: 0px;
    }
`;

const Tick = () => (
    <TickContainer>
        <svg
            width="485"
            height="286"
            viewBox="0 0 971 572"
            fill="none"
            animate="1"
            isie="0"
            isedge="0"
            className="sc-gxMtzJ iaGprq"
        >
            <path
                d="M15 557.499L213.186 360.685L312.622 460.806L607.5 162.499L707.622 263.306L955.929 15"
                stroke="url(#paintXLinear)"
                strokeWidth="40"
            />
            <defs>
                <linearGradient
                    id="paintXLinear"
                    x1="783.621"
                    y1="-111"
                    x2="50.5"
                    y2="622.121"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0520833" stopColor="#00FF00" stopOpacity="0" />
                    <stop offset="0.479167" stopColor="#00FF00" />
                    <stop offset="0.6875" stopColor="#00FF00" />
                    <stop offset="0.947917" stopColor="#00FF00" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    </TickContainer>
);

const Hero = () => {
    const { activeSection } = useContext(Context);

    // NOTE: if we use "hero" some logic somewhere messes up the margins, we we use "first"
    const inView = activeSection.name === 'first';

    // NOTE: this is used in conjuntion with some logic in the Menu component to "fake" a back button for the first section of the IM homepage
    const displayName = '⟵ Back';

    return (
        <Section name="first" displayName={displayName} invert minHeightWithLimit>
            <SectionContentWrapper>
                <SectionIntro>
                    <StyledHeader invert underline isActive={inView}>
                        AI Transformation
                        <br />
                        Management
                    </StyledHeader>
                </SectionIntro>

                <SectionContent>
                    <StatementWrapper isActive={inView} order={1}>
                        <StatementCopy>
                            <StyledSubHeader invert>
                                <Highlighted>AI is reshaping our world</Highlighted>. As organisations globally digitise
                                and transform, AI is becoming a primary force for value creation.
                            </StyledSubHeader>

                            <StyledDescription colour="graphite">
                                AI Transformation Investments is the investment management division of the Vesparum
                                Group.
                            </StyledDescription>

                            <StyledDescription colour="graphite">
                                We bring specialist expertise in AI and are focused on partnering with companies seeking
                                access to both capital and AI solutions to create transformative value
                            </StyledDescription>

                            <CtaButtonWrapper isActive={inView}>
                                <CtaButton
                                    hash="capital-solutions"
                                    color="vision"
                                    textColor="graphite"
                                    customMargin="5px 0"
                                >
                                    Capital solutions
                                </CtaButton>

                                <CtaButton hash="ai-solutions" color="vision" textColor="graphite" customMargin="5px 0">
                                    AI solutions
                                </CtaButton>
                            </CtaButtonWrapper>
                        </StatementCopy>
                    </StatementWrapper>
                </SectionContent>
            </SectionContentWrapper>

            <Tick />
        </Section>
    );
};

export default Hero;
